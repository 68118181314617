import getConfig from "next/config";
import React from "react";
import { incidentLayerSets } from "../../../config/layers/layerSets";
import { SessionStorageKeys } from "../../../config/storage";
import { incidentMapToolsets } from "../../../config/tools";
import { useMapStoreContext } from "../../map/Map/MapStoreProvider";
import Visualiser from "../../ui/Visualiser/Visualiser";
import { ActiveLayersProvider } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import { useSessionStorageActiveLayers } from "../../util/ActiveLayersProvider/useSessionStorageActiveLayers";
import {
  DEFAULT_INCIDENTS_SCOPE,
  IncidentsScopeProvider,
} from "../IncidentsScopeProvider/IncidentsScopeProvider";

const { publicRuntimeConfig } = getConfig();
const { NEXT_MAPBOX_ACCESS_TOKEN } = publicRuntimeConfig;

interface StateViewVisualiserProps {
  children?: React.ReactNode;
}

const StateViewVisualiser = ({ children }: StateViewVisualiserProps) => {
  const activeLayers = useSessionStorageActiveLayers({
    key: SessionStorageKeys.ACTIVE_LAYERS_STATE_VIEW,
  });
  const { bounds, setBounds } = useMapStoreContext();

  return (
    <ActiveLayersProvider {...activeLayers}>
      <Visualiser
        initialBounds={bounds}
        layerSets={incidentLayerSets}
        lib="mapbox"
        mapboxAccessToken={NEXT_MAPBOX_ACCESS_TOKEN}
        onBoundsChange={setBounds}
        toolsets={incidentMapToolsets}
      >
        <IncidentsScopeProvider defaultScope={DEFAULT_INCIDENTS_SCOPE}>
          {children}
        </IncidentsScopeProvider>
      </Visualiser>
    </ActiveLayersProvider>
  );
};

export default StateViewVisualiser;
