import { Item } from "@react-stately/collections";
import getConfig from "next/config";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import { useDeveloperOptions } from "../../util/DeveloperOptionsProvider/DeveloperOptionsProvider";
import HeaderNavLinkTabList from "../HeaderNavLinkTabList/HeaderNavLinkTabList";

const { publicRuntimeConfig } = getConfig();
const { COP_ENABLED, DYNAMIC_COVERAGE_ENABLED, OPERATIONS_ENABLED } =
  publicRuntimeConfig;

const IncidentsNavTabList = () => {
  const { options } = useDeveloperOptions();
  const { hasPrivilege: hasCoveragePrivilege } = useHasPrivilege({
    requestedPermissions: ["brigade:read", "resourcecoverage:write"],
  });

  return (
    <HeaderNavLinkTabList
      items={[
        {
          as: `/`,
          exact: true,
          href: "/",
          id: "map",
          title: "Map",
        },
        {
          as: `/incidents`,
          exact: true,
          href: "/incidents",
          id: "all-incidents",
          title: COP_ENABLED ? "Incidents" : "All Incidents",
        },
        ...(OPERATIONS_ENABLED && options.isOperationsEnabled
          ? [
              {
                as: "/operations",
                href: "/operations",
                id: "operations",
                title: "Operations",
              },
            ]
          : []),
        {
          as: `/resources`,
          href: "/resources",
          id: "resources",
          title: "Resources",
        },
        ...(DYNAMIC_COVERAGE_ENABLED && hasCoveragePrivilege
          ? [
              {
                as: `/coverage`,
                href: "/coverage",
                id: "coverage",
                title: "Coverage",
              },
            ]
          : []),
      ]}
      data-testid="incidents-nav-tab-list"
    >
      {(item) => <Item key={item.id}>{item.title}</Item>}
    </HeaderNavLinkTabList>
  );
};

export default IncidentsNavTabList;
